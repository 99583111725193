<div class="page-header">
  <h1>{{ "scim" | i18n }}</h1>
</div>

<p>{{ "scimDescription" | i18n }}</p>

<div *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="sr-only">{{ "loading" | i18n }}</span>
</div>
<form
  #form
  (ngSubmit)="submit()"
  [appApiAction]="formPromise"
  [formGroup]="formData"
  *ngIf="!loading"
>
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        id="enabled"
        [formControl]="enabled"
        name="Enabled"
        aria-describedby="scimEnabledCheckboxDescHelpText"
      />
      <label class="form-check-label" for="enabled">{{ "scimEnabledCheckboxDesc" | i18n }}</label>
      <div id="scimEnabledCheckboxDescHelpText">
        <small class="form-text text-muted">{{ "scimEnabledCheckboxDescHelpText" | i18n }}</small>
      </div>
    </div>
  </div>

  <bit-form-field *ngIf="showScimSettings">
    <bit-label>{{ "scimUrl" | i18n }}</bit-label>
    <input bitInput type="text" formControlName="endpointUrl" />
    <button
      type="button"
      bitSuffix
      bitButton
      (click)="copyScimUrl()"
      [appA11yTitle]="'copyScimUrl' | i18n"
    >
      <i aria-hidden="true" class="bwi bwi-lg bwi-clone"></i>
    </button>
  </bit-form-field>

  <bit-form-field *ngIf="showScimSettings">
    <bit-label>{{ "scimApiKey" | i18n }}</bit-label>
    <input bitInput type="text" formControlName="clientSecret" />
    <ng-container #rotateButton [appApiAction]="rotatePromise">
      <button
        [disabled]="rotateButton.loading"
        type="button"
        bitSuffix
        bitButton
        (click)="rotateScimKey()"
        [appA11yTitle]="'rotateScimKey' | i18n"
      >
        <i
          aria-hidden="true"
          class="bwi bwi-lg bwi-generate"
          [ngClass]="{ 'bwi-spin': rotateButton.loading }"
        ></i>
      </button>
    </ng-container>
    <button
      type="button"
      bitSuffix
      bitButton
      (click)="copyScimKey()"
      [appA11yTitle]="'copyScimKey' | i18n"
    >
      <i aria-hidden="true" class="bwi bwi-lg bwi-clone"></i>
    </button>
    <bit-hint>{{ "scimApiKeyHelperText" | i18n }}</bit-hint>
  </bit-form-field>

  <button
    type="submit"
    buttonType="primary"
    bitButton
    [loading]="form.loading"
    [disabled]="form.loading"
  >
    {{ "save" | i18n }}
  </button>
</form>
